//jQuery docready
$(function(){
  // Browser Test
  // Because reality
  //
    var chrome   = navigator.userAgent.indexOf('Chrome') > -1;
    var explorer = navigator.userAgent.indexOf('MSIE') > -1;
    var firefox  = navigator.userAgent.indexOf('Firefox') > -1;
    var safari   = navigator.userAgent.indexOf('Safari') > -1;
    var camino   = navigator.userAgent.indexOf('Camino') > -1;
    var opera    = navigator.userAgent.toLowerCase().indexOf('op') > -1;
    if ((chrome) && (safari)) safari = false;
    if ((chrome) && (opera)) chrome = false;

  // Scrollbar Test
  // Because some people still have scroll-wheel mice. yuuuuuk!
  //
    var hasScrollbar = window.innerWidth > document.documentElement.clientWidth;
    if (hasScrollbar) {
        // scrollbar
        $('body').addClass('scrollbar');
    }
  // animation end variables
    var transitionend = 'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend';
    var animationend = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';

  // switch navbar if scrolled or window width < 1199px;
  // console.log('window width is '+$(window).width());
  $(window).on('scroll resize', function() {
    if ( $(window).scrollTop() >= 1 || $(window).width() <= 1199 ) {
      $('body').addClass('scrolled');
      $('nav').addClass('navbar-inverse').removeClass('navbar-default');
    } else {
      $('body').removeClass('scrolled');
      $('nav').addClass('navbar-default').removeClass('navbar-inverse');
    }
  });



  // ScrollToHash
  // Basic utility to animate scrolling to anchored sections
  // Usage: section id 'something-id'. Link to it: 'path/page.html#something'
  //
  function scrollToHash(hash) {
    var target = $(hash);
    var targetTop = target.offset().top;
    var addTop = 0;
    if (hash == '#reel-id') {
      addTop = -60;
    }
    window.requestAnimationFrame(function() {
      $('html,body').animate({scrollTop: targetTop + addTop}, 250);
    });
    // todo: make sure nav is closed
    // $('.la-nav-toggle').removeClass('open').blur();
    // $('.la-nav-overlay').removeClass('open');
  }
  $(window).on('hashchange', function() {
    if (location.hash.length) {
      var hash = location.hash;
      hash += '-id';
      scrollToHash(hash);
      ga('send', 'event', 'scrollto', 'click', hash);
    }
  });
  $(window).trigger('hashchange');


  // viewport utils with waypoints
  $('.waypoint')
    .waypoint(function(direction) {
      // triggers when the top of the element is scrolled to or away from
      var $this = $(this.element);
      if (direction == 'down') {
        $this.addClass('in-down');
        $this.removeClass('out-down');
        $this.removeClass('out-up');
        $this.removeClass('in-up');
      } else {
        $this.addClass('out-up');
        $this.removeClass('out-down');
        $this.removeClass('in-down');
        $this.removeClass('in-up');
      }
    }, {
      offset: function() { return $(window).height(); }
    });
  $('.waypoint')
    .waypoint(function(direction) {
      // triggers when near the bottom of the element
      var $this = $(this.element);
      if (direction == 'down') {
        $this.addClass('out-down');
        $this.removeClass('in-down');
        $this.removeClass('out-up');
        $this.removeClass('in-up');
      } else {
        $this.addClass('in-up');
        $this.removeClass('out-down');
        $this.removeClass('out-up');
        $this.removeClass('in-down');
      }
    }, {
      offset: function() {  return -$(this.element).height() + 100; }
    });

// parallax scrolling
    var parallaxElements = $('.parallax'),
        parallaxQuantity = parallaxElements.length,
        windowHeight = window.innerHeight;

    function updatePositions() {
      windowHeight = window.innerHeight;
      parallaxElements.each(function() {
        var elObj = $(this)[0];
        var initialOffset = $(this).offset().top;
        elObj.initialOffset = initialOffset;
        // console.log('elObj.initialOffset is '+elObj.initialOffset);
        // var baseline = initialOffset + elObj.height;
        var baseline = initialOffset + elObj.offsetHeight;
        // console.log('elObj.offsetHeight is '+elObj.offsetHeight);
        elObj.baseline = baseline;
        // console.log('elObj.baseline is '+elObj.baseline);
        if ($(this).is('.parallax-horizontal')) {
          elObj.horizontal = true;
        }
      });
    }

    updatePositions();

    $(window).on('load resize', function() {
      // update positions again on window load for some browsers
      // also update on window resize
      updatePositions();
    });

    $(window).on('scroll', function () {

      window.requestAnimationFrame(function () {

        for (var i = 0; i < parallaxQuantity; i++) {

          var currentElement = parallaxElements.eq(i),
              scrolled = window.scrollY,
              offset = currentElement[0].initialOffset,
              elbaseline = currentElement[0].baseline,
              horizontal = currentElement[0].horizontal,
              speed = currentElement.data('speed'),
              topLimit = (offset - windowHeight),
              bottomLimit = elbaseline;

          var onscreen = false;
          if (scrolled >= (topLimit - 100) && scrolled <= bottomLimit + 100) {
            onscreen = true;
          }

          var position = (scrolled - offset) * speed;
          position = position.toFixed(2);

          if (onscreen) {
            if (horizontal) {
              currentElement.css({
                'transform': 'translate3d(' + position + 'px, 0, 0)'
              });
            } else {
              currentElement.css({
                'transform': 'translate3d(0, ' + position + 'px, 0)'
              });
            }
          }

        }
      });

    });

    // trigger scroll event once to init parallax positions
    $(window).trigger('scroll');

    // set currentyear
    $('#currentyear').text( (new Date()).getFullYear() );

    // tinkering with an instagram screen scraper to get community photo feed. Not yet completed.
    // if ($('body').hasClass('journey')) {
    //   $.ajax({
    //     type:'GET',
    //     url:'//jumafit.com/jumacorsproxy.php?url=https://www.instagram.com/explore/locations/202923817135953/juma-fit/',
    //     success: function(data) {
    //       console.log(data);
    //     },
    //     dataType: 'jsonp',
    //   });
    // }

}); // end jQuery docready
